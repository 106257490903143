<script>
export default {
  name: 'AppBanner',
  data () {
    return {
      show: false,
      hovering: false
    }
  },
  computed: {
    canShowBanner () {
      return this.$store.getters.getShowAppBanner
    },
    isIOS () {
      return !!navigator.platform.match(/iPhone|iPod|iPad/)
    },
    isAndroid () {
      return /(android)/i.test(navigator.userAgent)
    }
  },
  methods: {
    close () {
      this.$store.commit('setShowAppBanner', false)
    }
  },
  created () {
    setTimeout(() => {
      this.show = true
    }, 1000)
  }
}
</script>
<template>
  <transition name="slide-bottom-top">
    <v-card class="app-banner--container" v-if="show && canShowBanner" @mouseenter="hovering = true" @mouseleave="hovering = false">
      <span class="top-right">
        <v-btn icon @click="close" :color="getPrimaryColor">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <div class="app-banner--main-content" :class="{'blur': hovering}">
        <div class="app-banner--wrapper">
          <div class="app-banner--left-wrapper">
            <h2 class="app-banner--title">{{ $t('app.banner.desktop:title') }}</h2>
            <p>{{ $t('app.banner.desktop:description') }}</p>
          </div>
          <div class="app-banner--right-wrapper">
            <div class="image-wrapper desktop">
              <v-img src="/assets/images/app-qr-code.png" height="103px" width="102px"></v-img>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div class="app-banner--download-hover" v-if="hovering || isMobile">
          <v-btn text href="https://play.google.com/store/apps/details?id=br.com.rezolve.enlizt" target="_blank" v-if="!isIOS">
            <v-img src="/assets/images/button-googleplay.svg"></v-img>
          </v-btn>
          <v-btn text href="https://apps.apple.com/app/plooral-career-jobs/id1089645429" target="_blank" v-if="!isAndroid">
            <v-img src="/assets/images/button-appstore.svg"></v-img>
          </v-btn>
        </div>
      </transition>
    </v-card>
  </transition>
</template>
<style lang="scss">
.app-banner--container {
  width: 550px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 999999;
  .app-banner--main-content {
    filter: blur(0px);
    transition: all 0.2s ease-in-out;
    &.blur {
      filter: blur(2px);
    }
  }
  .top-right {
    top: 8px;
  }
  .app-banner--wrapper {
    border-top: 2px solid #1200D3;
    display: flex;
    align-items: center;
    padding: 30px 30px 20px;
    .app-banner--left-wrapper {
      width: calc(100% - 140px);
      h2.app-banner--title {
        font-size: 24px;
        line-height: 30px;
        text-align: left;
        color:#1200D3;
        font-family: "Lato", sans-serif;
      }
      p {
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin-top: 12px;
        font-family: "Lato", sans-serif;
      }
    }
    .app-banner--right-wrapper {
      display: flex;
      justify-content: center;
      width: 120px;
    }
  }
  .app-banner--download-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: center;
    .v-btn {
      padding: 0;
      margin: 0 16px;
      .v-image {
        z-index: 2;
      }
      &::after {
        position: absolute;
        background: #fff;
        height: 40px;
        width: 120px;
        border-radius: 8px;
        display: block;
        content: '';
        z-index: 1;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    bottom: 0;
    left: 0;
    .app-banner--main-content {
      &.blur {
        filter: blur(0px);
      }
    }
    .app-banner--wrapper {
      padding-top: 40px;
    }
    .app-banner--main-content {
      .app-banner--left-wrapper {
        width: 100%
      }
      .app-banner--right-wrapper {
        display: none;
        .image-wrapper.desktop {
        }
      }
    }
    .app-banner--download-hover {
      position: relative;
    }
  }
}
</style>
